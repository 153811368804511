const EmbedStructuredData = ({
	id,
	scriptAsString,
	scriptSrc,
	scriptAsync,
	type,
}: any) => {
	const scriptAsyncText = scriptAsync ? 'async' : '';

	const innerHTMLObj = scriptAsString
		? {
				dangerouslySetInnerHTML: { __html: scriptAsString },
		  }
		: { dangerouslySetInnerHTML: null };

	let props = {
		id,
		type,
		...innerHTMLObj,
		scriptAsyncText,
		src: scriptSrc,
	};

	for (let prop in props) {
		if (
			// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
			props[prop] === null ||
			// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
			props[prop] === undefined ||
			// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
			props[prop] === ''
		) {
			// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
			delete props[prop];
		}
	}

	return props;
};

export default EmbedStructuredData;
