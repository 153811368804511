import React from 'react';

import { useScrollIntoView } from 'Hooks/useScrollIntoView';
import { debounce } from 'Utils/gen';
import PlatformUtils from 'Utils/platformUtils';

const rootMargin = PlatformUtils.isDesktop()
	? '0px 0px -250px 0px'
	: '0px 0px -200px 0px';

const fireOnIntersectionOnly = true;

/**
 * Needs the tracked child element to have
 * `core-section-header-container` class for
 * correct `ranking` calculation.
 */

export const TrackScrollIntoView = ({
	callback,
	children,
	debounceDelay = 300,
}: {
	callback?: (ref: any) => void;
	children: React.ReactElement;
	debounceDelay?: number;
}) => {
	const ref = useScrollIntoView(
		debounce(([entry]: any) => {
			if (entry.isIntersecting) {
				callback && callback(ref);
			} else if (!fireOnIntersectionOnly) {
				callback && callback(ref);
			}
		}, debounceDelay),
		{
			rootMargin,
		},
	);

	return React.cloneElement(children, {
		ref,
	});
};
