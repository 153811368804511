import styled from 'styled-components';

import getFontDetailsByLabel from '@headout/aer/src/tokens/typography';

import colors from 'Static/typography/colors';
import TYPE_LABELS from 'Static/typography/labels';

export const ThingsToDoSectionWrapper = styled.div<{
	$isOnExperiencePage?: boolean;
	ref?: React.MutableRefObject<any>;
}>`
	max-width: 75rem;
	min-width: 62.5rem;
	margin: 4.5rem auto 0 auto;
	display: flex;
	flex-direction: column;
	width: 100%;

	.horizontalTabSection {
		margin-top: 2rem;
	}

	.feed-section-header-title {
		color: ${colors.GREY_DS.G2};
		${getFontDetailsByLabel(TYPE_LABELS.HEADING_LARGE)};
	}

	@media (max-width: 768px) {
		max-width: unset;
		min-width: unset;
		width: 100%;
		margin: 3rem 0;

		.horizontalTabSection {
			margin-top: 1.5rem;
		}

		.feed-section-header-title {
			${getFontDetailsByLabel(TYPE_LABELS.HEADING_SMALL)};
			margin: 0 1.5rem;
		}

		${({ $isOnExperiencePage }) =>
			$isOnExperiencePage &&
			`.feed-section-header-title {
			margin: 0;
		}
			.core-section-header {
				${getFontDetailsByLabel(TYPE_LABELS.HEADING_REGULAR)};
            }

            .horizontalTabSection {
                margin-left: -1.5rem;
            }
        `}

		details {
			border-top: none;
			border-bottom: 1px solid ${colors.GREY_DS.G6};
			padding-bottom: 1.5rem;

			summary {
				padding-top: 0;

				&:focus-visible {
					outline: 0.125rem solid ${colors.BRAND_COLORS.PURPS};
					outline-offset: 0.125rem;
					border-radius: 0.25rem;
				}
			}

			&[open] {
				.heading {
					margin-bottom: 1rem;
				}

				.horizontalTabSection {
					margin-top: 0.5rem;
				}
			}
		}
	}
`;
