/**
 * Renders first truthy child component based on the @positionalCondition parameter.
 * (default) renders the last child if all @positionalConditions are falsy.
 * @example
 *  <RenderOneOf positionalConditions={[
 *      false,
 *      true,
 *      0
 *  ]}>
 *    <NotRendered />
 *    <Rendered/>
 *    <NotRendered />
 *  </RenderOneOf>
 */
const RenderOneOf = ({
	children,
	positionalConditions,
	noDefault = false,
}: any) => {
	const indexOfFirstTrueChild = positionalConditions
		.map((condition: any) => !!condition)
		.indexOf(true);
	return (
		children[indexOfFirstTrueChild] ||
		(noDefault ? null : children[children.length - 1]) ||
		null
	);
};

export default RenderOneOf;
