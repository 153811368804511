import { useEffect, useRef } from 'react';

export const useScrollIntoView = (
	callback: IntersectionObserverCallback,
	{
		delay = 300,
		fireOnce = false,
		fireOnIntersectionOnly = false,
		...options
	},
) => {
	const elRef = useRef(null);

	useEffect(() => {
		if (!callback || !elRef.current) return;
		if (typeof window.IntersectionObserver === 'undefined') return;

		const observer = new IntersectionObserver(callback, options);
		observer.observe(elRef.current);

		return () => observer.disconnect();
	}, [callback]);

	return elRef;
};
