import React, { useEffect, useRef } from 'react';
import dynamic from 'next/dynamic';
import styled from 'styled-components';

import getFontDetailsByLabel from '@headout/aer/src/tokens/typography';

import Video from 'Components/common/video';

import PlatformUtils from 'Utils/platformUtils';

import {
	HOME_PAGE_FALLBACK_BANNER_DWEB,
	HOME_PAGE_FALLBACK_BANNER_MWEB,
	HOME_PAGE_VIDEO_BANNER_DWEB,
	HOME_PAGE_VIDEO_BANNER_MWEB,
	SEARCH_LOCATION,
} from 'Constants/constants';
import { strings } from 'Constants/strings';

import colors from 'Static/typography/colors';
import TYPE_LABELS from 'Static/typography/labels';

const Searchbox = dynamic(() => import('Components/common/searchBox'));
const UniversalSearch = dynamic(
	() => import('Containers/desktop/universalSearch'),
);

const DWEB_WIDTH = 1440;
const DWEB_HEIGHT = 640;
const MWEB_WIDTH = 450;
const MWEB_HEIGHT = 414;

const StaticBannerStyled = styled.div`
	position: relative;
	z-index: 10;
	width: 100%;
	height: ${MWEB_HEIGHT}px;
	background: transparent;
	margin-top: -3.5rem;
	@media (min-width: 768px) {
		position: relative;
		height: ${DWEB_HEIGHT}px;
		margin: 0;
		margin-top: -5rem;
	}
`;

const BannerTextContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-start;
	width: 100%;
	height: 100%;
	max-width: 75rem;
	margin: 0 auto;
	padding-bottom: 4rem;
	gap: 2.625rem;

	@media (max-width: 768px) {
		align-items: left;
		width: 100%;
		max-width: 100%;
		padding: 1.5rem;
		gap: 1.5rem;
	}
`;

const BannerHeading = styled.h1`
	color: ${colors.WHITE};
	letter-spacing: 0.6px;
	font-family: halyard-display, -apple-system, BlinkMacSystemFont, 'Segoe UI',
		Roboto, Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 3rem;
	line-height: 54px;
	letter-spacing: 0.822222px;
	text-shadow: 0px 0px 54.8148px #000000;
	max-width: 37.5rem;
	z-index: 1;

	@media (max-width: 768px) {
		${getFontDetailsByLabel(TYPE_LABELS.HEADING_LARGE)}
		max-width: 100%;
		text-align: left;
	}
`;

const StyledVideoContainer = styled.div`
	z-index: 0;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	overflow: hidden;
	video {
		height: ${MWEB_HEIGHT}px;
		object-fit: cover;
		transform: scale(1);
		transition: transform linear 50ms;
	}
	img {
		width: 100%;
		height: 100% !important;
		object-fit: cover;
	}
	@media (min-width: 768px) {
		video {
			height: ${DWEB_HEIGHT}px;
			object-fit: cover;
		}
	}
`;

const StyledOverlay = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
`;

type Props = {
	banner: { redirectUrl: string; imageUrl: string };
	searchBarProps?: any;
};

const percentageSeen = (scrollPosY: any, isMobile: any) => {
	const height = isMobile ? MWEB_HEIGHT : DWEB_HEIGHT;
	return scrollPosY / height;
};

const StaticBanner = ({ searchBarProps }: Props) => {
	const bannerRef = useRef(null);
	const isMobile = !PlatformUtils.isDesktop();

	useEffect(() => {
		if (!bannerRef.current) return;
		const videoElement = (bannerRef.current as any).querySelector('video');
		let isVisible = false;
		let scrollPosY = 0;
		const observer = new IntersectionObserver(
			([entry]) => {
				isVisible = entry.isIntersecting;
			},
			{ rootMargin: '20px 0px 0px 0px' },
		);
		observer.observe(bannerRef.current);
		const onScroll = () => {
			if (!isVisible) return;
			scrollPosY = window.scrollY;
			videoElement.style.transform = `scale(${Math.max(
				1 + 0.5 * percentageSeen(scrollPosY, isMobile),
				1,
			)})`;
		};
		document.addEventListener('scroll', onScroll);
		return () => {
			observer.disconnect();
			document.removeEventListener('scroll', onScroll);
		};
	}, []);

	return (
		<StaticBannerStyled ref={bannerRef}>
			<BannerTextContainer>
				<BannerHeading>
					{strings.HOME_PAGE_BANNER_HEADING}
				</BannerHeading>
				{isMobile ? (
					<Searchbox searchLocation={SEARCH_LOCATION.MID} />
				) : (
					<UniversalSearch {...searchBarProps} />
				)}
			</BannerTextContainer>
			<StyledVideoContainer>
				<Video
					url={
						isMobile
							? HOME_PAGE_VIDEO_BANNER_MWEB
							: HOME_PAGE_VIDEO_BANNER_DWEB
					}
					isLooped
					isMuted
					shouldAutoPlay={true}
					currentlyPlaying={true}
					isBanner
					width={isMobile ? MWEB_WIDTH : DWEB_WIDTH}
					height={isMobile ? MWEB_HEIGHT : DWEB_HEIGHT}
					fallbackImage={{
						url: isMobile
							? HOME_PAGE_FALLBACK_BANNER_MWEB
							: HOME_PAGE_FALLBACK_BANNER_DWEB,
						altText:
							"The world's best experiences curated just for you.",
					}}
					responsive={true}
					aria={{
						label: 'Various cities and experiences showcase',
					}}
					imageDensity={1.2}
					priortizeImage={true}
				/>
			</StyledVideoContainer>
			<StyledOverlay />
		</StaticBannerStyled>
	);
};

export default StaticBanner;
