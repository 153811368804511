import { getBaseUrl } from 'Utils/urlUtils';

import { HEADOUT_SUPPORT_LINE } from 'Constants/constants';

export const ORGANIZATION = {
	NAME: 'Headout',
	URL: getBaseUrl(),
	LOGO: 'https://cdn-imgix-open.headout.com/logo/headout-logo.png',
	IMAGE: 'https://cdn-imgix-open.headout.com/logo/headout-logo.png',
	DESCRIPTION:
		'Headout Inc. is an on-demand mobile concierge service that helps travelers and natives to discover and book tours, local experiences and various other activities on the go.',
	ADDRESS: {
		STREET_ADDRESS: '82 Nassau St #60351',
		ADDRESS_LOCALITY: 'New York City',
		ADDRESS_REGION: 'NY',
		POSTAL_CODE: '10038',
		ADDRESS_COUNTRY: 'USA',
	},
	CONTACT_POINT: {
		TELEPHONE: HEADOUT_SUPPORT_LINE,
		CONTACT_TYPE: 'customer service',
	},
	EMAIL: 'contact@headout.com',
	SAME_AS: [
		'https://www.wikidata.org/wiki/Q56290808',
		'https://twitter.com/headout',
		'https://www.facebook.com/headoutapp',
		'https://www.pinterest.com/headoutapp/',
		'https://www.instagram.com/headout/',
		'https://in.linkedin.com/company/headout-com',
		'https://www.youtube.com/channel/UCb6JNJlk_HwKF0XWsIEIkDA',
		'https://www.crunchbase.com/organization/tourlandish',
	],
};
export const WEBSITE = {
	NAME: 'Headout',
	URL: getBaseUrl(),
	POTENTIAL_ACTION: {
		TARGET: `${getBaseUrl()}/search?q={search_term_string}`,
		QUERY_INPUT: 'required name=search_term_string',
	},
};
const SCHEMA_ORG_META_DATA = {
	ORGANIZATION: ORGANIZATION,
	WEBSITE: WEBSITE,
};

export default SCHEMA_ORG_META_DATA;
