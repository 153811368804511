import * as React from 'react';

export const ChevronRightSvg = (props: any) => {
	return (
		<svg
			viewBox='0 0 6 12'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M0.5 11L5.5 6L0.5 1'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};
