import React, { forwardRef } from 'react';
import styled from 'styled-components';

import getFontDetailsByLabel from '@headout/aer/src/tokens/typography';

import Conditional from 'Components/common/conditional';

import colors from 'Static/typography/colors';
import TYPE_LABELS from 'Static/typography/labels';

/**
 * This core component implements a section header with the
 * ability to add custom call to action elements via children
 *
 * @NOTE: Use "block" classes to allow overrides
 */

const TitleText = styled.h1`
	&.feed-section-header-title {
		color: ${colors.GREY['44']};
		${getFontDetailsByLabel(TYPE_LABELS.HEADING_LARGE)};
	}
`;

type Props = {
	title?: string;
	subtitle?: string;
	description?: string;
	children?: React.ReactNode;
	headingType?: 'h1' | 'h2';
	titleStyleClassName?: string;
};

const SectionHeader = (props: Props, ref: any) => {
	const {
		title,
		subtitle,
		description,
		children,
		titleStyleClassName,
		headingType,
	} = props;
	const computedTitleClassName = `block core-text-header core-section-header ${titleStyleClassName}`;
	return (
		<div ref={ref} className='core-section-header-container'>
			<div className='core-section-header-main-content'>
				<div className='core-section-text-container'>
					<Conditional if={title}>
						<TitleText
							className={computedTitleClassName}
							as={headingType || 'h2'}
						>
							{title}
						</TitleText>
					</Conditional>

					<Conditional if={subtitle}>
						<p className='block core-text core-section-text'>
							{subtitle}
						</p>
					</Conditional>
				</div>

				<Conditional if={children}>
					<div className='core-section-cta'>{children}</div>
				</Conditional>
			</div>

			<Conditional if={description}>
				<p className='block core-text core-section-text'>
					{description}
				</p>
			</Conditional>
		</div>
	);
};

SectionHeader.defaultProps = {
	titleStyleClassName: '',
	headingType: 'h2',
};

// @ts-expect-error TS(2345): Argument of type '{ (props: Props, ref: any): Elem... Remove this comment to see the full error message
export default forwardRef(SectionHeader);
