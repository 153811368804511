import React, { forwardRef } from 'react';
import Link from 'next/link';

import CategorySubCategoryIcon from 'Components/common/categoriesAndSubCategories/categorySubCategoryIcon';
import {
	SubCategoriesContainer,
	SubCategory,
} from 'Components/common/categoriesAndSubCategories/style';
import { IStarredCategoriesProps } from 'Components/common/starredCategories/interface';

import { getCategoryIconUrl } from 'Utils/imageUtils';

const StarredCategories = forwardRef<HTMLDivElement, IStarredCategoriesProps>(
	({ starredCategoriesAndSubcategories, trackClick }, ref) => {
		if (!starredCategoriesAndSubcategories.length) return null;

		return (
			<SubCategoriesContainer $isActive $isExperiencePage ref={ref}>
				{starredCategoriesAndSubcategories?.map(
					({
						entityDetails: {
							name,
							id,
							as,
							href,
							actualName,
							isCategory,
							heading,
						},
						rank,
					}) => {
						const svgUrl = getCategoryIconUrl({
							entityId: id,
							isCategory,
						});

						const finalName = name ?? actualName;

						const subCategoryText = heading ?? finalName;

						return (
							// @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
							<Link href={href} as={as} passHref legacyBehavior>
								<SubCategory
									onClick={() =>
										trackClick?.({ name: actualName, rank })
									}
								>
									<CategorySubCategoryIcon
										isActive={false}
										isCategory={isCategory}
										isStarred={true}
										name={subCategoryText!}
										svgUrl={svgUrl}
										className='subCategoryIcon'
									/>
									<span className='subCategoryText'>
										{subCategoryText}
									</span>
								</SubCategory>
							</Link>
						);
					},
				)}
			</SubCategoriesContainer>
		);
	},
);

export default StarredCategories;
