import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import CategoriesAndSubCategories from 'Components/common/categoriesAndSubCategories';
import FeedSectionHeader from 'Components/common/feedSectionHeader';
import RenderOneOf from 'Components/common/renderOneOf';
import StarredCategories from 'Components/common/starredCategories';

import useOnScreen from 'Hooks/useOnScreen';
import { trackEvent } from 'Utils/analytics';
import { thingsToDoProps } from 'Utils/collectionsUtils';

import { ANALYTICS_EVENTS, ANALYTICS_PROPERTIES } from 'Constants/analytics';
import { strings } from 'Constants/strings';

import {
	ICategoriesAndSubCategories,
	IThingsToDoSectionProps,
	TCategoriesAndSubCategoriesMap,
	TStarredCategoriesAndSubCategoriesList,
} from './interface';
import { ThingsToDoSectionWrapper } from './style';

const ThingsToDoSection = ({
	cityDisplayName,
	categoriesAndSubCategoriesInfo,
	starredCategoriesInfo,
	categoryId,
	isOnExperiencePage,
	onScrollIntoView,
	isMobile,
}: IThingsToDoSectionProps) => {
	const ref = useRef<HTMLDivElement>(null);
	const isOnScreen = useOnScreen({ ref });
	const [isViewedEventTracked, setIsViewedEventTracked] = useState(false);
	const [
		starredCategoriesAndSubCategoriesList,
		setStarredCategoriesAndSubCategoriesList,
	] = useState<TStarredCategoriesAndSubCategoriesList>([]);
	const [categoriesAndSubCategoriesMap, setCategoriesAndSubCategoriesMap] =
		useState<TCategoriesAndSubCategoriesMap>(
			new Map<number, ICategoriesAndSubCategories>(),
		);
	const showStarredCategories = Boolean(
		// @ts-expect-error TS(2532): Object is possibly 'undefined'.
		isOnExperiencePage && starredCategoriesInfo.length,
	);

	useEffect(() => {
		let entitiesInfo = [...categoriesAndSubCategoriesInfo];

		if (categoryId) {
			entitiesInfo = entitiesInfo.filter(entity =>
				entity.isCategory
					? entity.id !== categoryId
					: entity.categoryId !== categoryId,
			);
		}

		if (!entitiesInfo.length) return;

		const catAndSubCatMap: TCategoriesAndSubCategoriesMap = new Map<
			number,
			ICategoriesAndSubCategories
		>();

		entitiesInfo.forEach(category => {
			const { id, isCategory, categoryId } = category;
			if (isCategory) {
				catAndSubCatMap.set(+id, {
					entityDetails: category,
					// @ts-expect-error TS(2322): Type 'false | never[]' is not assignable to type '... Remove this comment to see the full error message
					subCategories:
						(!isOnExperiencePage || !showStarredCategories) && [],
				});
			} else {
				if (showStarredCategories)
					catAndSubCatMap.set(+id, {
						entityDetails: category,
					});
				else
					catAndSubCatMap
						.get(categoryId!)
						?.subCategories?.push(category);
			}
		});

		setCategoriesAndSubCategoriesMap(catAndSubCatMap);

		if (isOnExperiencePage) {
			const starredCatAndSubcatList: TStarredCategoriesAndSubCategoriesList =
				[];

			starredCategoriesInfo?.forEach(({ id, rank }) => {
				const entityData = catAndSubCatMap.get(id);
				// If category/subcategory doesn't have any active product
				if (!entityData) return;

				const { entityDetails } = entityData;
				starredCatAndSubcatList.push({
					entityDetails,
					rank,
				});
			});

			setStarredCategoriesAndSubCategoriesList(starredCatAndSubcatList);
		}
	}, []);

	useEffect(() => {
		if (
			!isViewedEventTracked &&
			isOnScreen &&
			(starredCategoriesAndSubCategoriesList ||
				categoriesAndSubCategoriesMap)
		) {
			trackEvent({ eventName: ANALYTICS_EVENTS.BROWSE_BY_THEMES.VIEWED });
			setIsViewedEventTracked(true);
		}
	}, [
		isOnScreen,
		categoriesAndSubCategoriesMap,
		starredCategoriesAndSubCategoriesList,
	]);

	const trackThemeSelected = ({
		name,
		rank,
	}: {
		name: string;
		rank?: number;
	}) => {
		trackEvent({
			eventName: ANALYTICS_EVENTS.BROWSE_BY_THEMES.THEME_SELECTED,
			[ANALYTICS_PROPERTIES.BROWSE_BY_THEMES.LABEL]: name,
			...(typeof rank === 'number' && {
				[ANALYTICS_PROPERTIES.BROWSE_BY_THEMES.RANK]: rank,
			}),
		});
	};

	if (!(showStarredCategories || categoriesAndSubCategoriesInfo.length))
		return null;

	return (
		<ThingsToDoSectionWrapper $isOnExperiencePage={isOnExperiencePage}>
			<FeedSectionHeader
				title={strings.THINGS_TO_DO.CITY}
				headingType={'h2'}
				newDesignSystem
				onScrollIntoView={onScrollIntoView}
			/>
			<RenderOneOf
				positionalConditions={[
					showStarredCategories,
					!showStarredCategories,
				]}
				noDefault
			>
				<StarredCategories
					starredCategoriesAndSubcategories={
						starredCategoriesAndSubCategoriesList
					}
					city={cityDisplayName}
					trackClick={trackThemeSelected}
					ref={ref}
				/>
				<CategoriesAndSubCategories
					className='horizontalTabSection'
					categoriesAndSubcategories={categoriesAndSubCategoriesMap}
					city={cityDisplayName}
					ref={ref}
					trackClick={trackThemeSelected}
					isMobile={isMobile}
				/>
			</RenderOneOf>
		</ThingsToDoSectionWrapper>
	);
};

export default connect(thingsToDoProps)(ThingsToDoSection);
