import React from 'react';

import SectionHeader from './sectionHeader';
import { TrackScrollIntoView } from './trackInViewWrapper';

type Props = {
	title?: string;
	headingType?: string;
	subtitle?: string;
	description?: string;
	children?: React.ReactNode;
	newDesignSystem?: boolean;
	onScrollIntoView?: (ref: any) => void;
};

const FeedSectionHeader = (props: Props) => {
	const { title, children, onScrollIntoView, ...otherProps } = props;

	return (
		<TrackScrollIntoView callback={onScrollIntoView}>
			{/* @ts-expect-error TS(2322): Type '{ children: ReactNode; headingType?: string ... Remove this comment to see the full error message */}
			<SectionHeader
				title={title}
				titleStyleClassName={'feed-section-header-title'}
				{...otherProps}
			>
				{children}
			</SectionHeader>
		</TrackScrollIntoView>
	);
};

export default FeedSectionHeader;
